.home-page {
  .btn-shadow {
    box-shadow: 0 rpx(20) rpx(30) rgba($color: $primary-color, $alpha: 0.3);
  }
  .function-btn {
    display: flex;
    flex-direction: column;
    width: rpx(157);
    height: rpx(90);
    padding: rpx(10);
    margin-top: rpx(10);
    position: relative;
    background-color: white;

    border-radius: rpx(10);
    box-sizing: border-box;
    &:nth-child(2n + 1) {
      margin-right: rpx(20);
    }
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  3% {
    transform: translateX(-5px);
  }
  6% {
    transform: translateX(5px);
  }
  9% {
    transform: translateX(-5px);
  }
  12% {
    transform: translateX(5px);
  }
  15% {
    transform: translateX(-5px);
  }
  16% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.shake-animation {
  animation: shake 4s; /* 抖动的持续时间 */
  animation-iteration-count: infinite; /* 动画重复次数 */
}

.favorite-dialog {
  height: 100vh;
  width: 100vw;

  .step-img-block {
    width: 80vw;
    height: 70vh;
    margin-left: 10vw;
    .slick-slider {
      height: 56vh;
    }
    .slider-img-block {
      text-align: center;
      .slider-img {
        height: 50vh;
        display: inline-block;
        width: auto;
      }
      .slider-index {
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        height: rpx(40);
        line-height: rpx(40);
      }
    }
    .slick-prev {
      left: rpx(10);
      z-index: 999;
      &::before {
        color: $text-color-9;
      }
    }
    .slick-next {
      right: rpx(10);
      z-index: 999;
      &::before {
        color: $text-color-9;
      }
    }
  }
}

.first-active-float {
  position: fixed;
  right: rpx(20);
  bottom: 50vh;
  z-index: 999;
  width: rpx(56);
  height: rpx(65);
}

@keyframes route {
  0% {
    transform: rotate(15deg);
  }
  3% {
    transform: rotate(-15deg);
  }
  6% {
    transform: rotate(15deg);
  }
  9% {
    transform: rotate(-15deg);
  }
  12% {
    transform: rotate(15deg);
  }
  15% {
    transform: rotate(-15deg);
  }
  16% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.route-animation {
  animation: route 4s; /* 抖动的持续时间 */
  animation-iteration-count: infinite; /* 动画重复次数 */
}
