.button {
  background-color: $primary-color;
  color: white;
  text-align: center;
  padding: 0 rpx(10);
  width: max-content;
  &.primary {
    &:active {
      background-color: rgba($color: $primary-color, $alpha: 0.8);
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $primary-color;
      color: $primary-color;
      &:active {
        background-color: rgba($color: $primary-color, $alpha: 0.1);
      }
      &.disabled {
        border-color: rgba($color: $primary-color, $alpha: 0.8);
        color: rgba($color: $primary-color, $alpha: 0.8);
        background-color: unset;
      }
    }
    &.disabled {
      background-color: rgba($color: $primary-color, $alpha: 0.8);
    }
  }
  &.gray {
    background-color: $bg-gray;
    color: $text-color-9;
    &:active {
      background-color: rgba($color: $bg-gray, $alpha: 0.8);
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $bg-gray;
      color: $bg-gray;
      &:active {
        background-color: rgba($color: $bg-gray, $alpha: 0.1);
      }
      &.disabled {
        border-color: rgba($color: $bg-gray, $alpha: 0.8);
        color: $text-color-9;
        background-color: unset;
      }
    }
    &.disabled {
      background-color: rgba($color: $bg-gray, $alpha: 0.8);
    }
  }
  &.origin-red {
    background-color: $text-color-origin-red;

    &:active {
      background-color: rgba($color: $text-color-origin-red, $alpha: 0.8);
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $text-color-origin-red;

      &:active {
        background-color: rgba($color: $text-color-origin-red, $alpha: 0.1);
      }
      &.disabled {
        border-color: rgba($color: $text-color-origin-red, $alpha: 0.8);

        background-color: unset;
      }
    }
    &.disabled {
      background-color: rgba($color: $text-color-origin-red, $alpha: 0.8);
    }
  }
  &.default {
    background-color: white;
    // border: 1px solid $text-color-9;
    color: $text-color-3;
    &:active {
      background-color: $bg-gray;
    }
    &.outline {
      background-color: transparent;
      // border: 1px solid $text-color-9;
      color: $text-color-3;
      &:active {
        background-color: $bg-gray;
      }
      &.disabled {
        // border-color: rgba($color: $text-color-9, $alpha: 0.8);
        color: rgba($color: $text-color-9, $alpha: 0.8);
        background-color: unset;
      }
    }
    &.disabled {
      background-color: $bg-gray;
    }
  }
  &.danger {
    background-color: $text-color-red;
    &:active {
      background-color: rgba($color: $text-color-red, $alpha: 0.8);
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $text-color-red;
      color: $text-color-red;
      &:active {
        background-color: rgba($color: $text-color-red, $alpha: 0.1);
      }
      &.disabled {
        border-color: rgba($color: $text-color-red, $alpha: 0.8);
        color: rgba($color: $text-color-red, $alpha: 0.8);
        background-color: unset;
      }
    }
    &.disabled {
      background-color: rgba($color: $text-color-red, $alpha: 0.8);
    }
  }
  &.green {
    background-color: $text-color-green;
    &:active {
      background-color: rgba($color: $text-color-green, $alpha: 0.8);
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $text-color-green;
      color: $text-color-green;
      &:active {
        background-color: rgba($color: $text-color-green, $alpha: 0.1);
      }
      &.disabled {
        border-color: rgba($color: $text-color-green, $alpha: 0.8);
        color: rgba($color: $text-color-green, $alpha: 0.8);
        background-color: unset;
      }
    }
    &.disabled {
      background-color: rgba($color: $text-color-green, $alpha: 0.8);
    }
  }
  &.warning {
    background-color: $text-color-origin;
    &:active {
      background-color: rgba($color: $text-color-origin, $alpha: 0.8);
    }
    &.outline {
      background-color: transparent;
      border: 1px solid $text-color-origin;
      color: $text-color-origin;
      &:active {
        background-color: rgba($color: $text-color-origin, $alpha: 0.1);
      }
      &.disabled {
        border-color: rgba($color: $text-color-origin, $alpha: 0.8);
        color: rgba($color: $text-color-origin, $alpha: 0.8);
        background-color: unset;
      }
    }
    &.disabled {
      background-color: rgba($color: $text-color-origin, $alpha: 0.8);
    }
  }
  &.lg {
    height: rpx(48);
    line-height: rpx(48);
    font-size: rpx(16);
    &.circle {
      border-radius: rpx(24);
    }
  }
  &.md {
    height: rpx(36);
    line-height: rpx(36);
    font-size: rpx(14);
    &.circle {
      border-radius: rpx(18);
    }
  }
  &.sm {
    height: rpx(28);
    line-height: rpx(28);
    font-size: rpx(12);
    &.circle {
      border-radius: rpx(14);
    }
  }
  &.xs {
    height: rpx(24);
    line-height: rpx(24);
    font-size: rpx(12);
    &.circle {
      border-radius: rpx(12);
    }
  }
  &.round {
    border-radius: rpx(4);
  }
  &.long {
    width: initial;
  }
}
