.complaint-page {
  .textarea {
    resize: none;
    border-bottom: 1px solid #f5f5f5;
    &:focus-visible {
      outline: none;
    }
  }
  .textarea::placeholder,
  .input-style::placeholder {
    color: $text-color-9;
  }
  .input-style {
    border-bottom: 1px solid $border-color !important;
  }
}
