.scrolling-text {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  line-height: rpx(35);
}

.scrolling-text span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite; /* 调整动画时长和效果 */
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
