.bottom-nav-bar {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .bottom-nav-bar-bottom {
    height: $bottom-nav-bar-height;
    .bottom-nav-bar-item {
      .icon {
        width: rpx(20);
        height: rpx(20);
      }
    }
  }
}
