.activity-page {
  .title-dot {
    display: inline-block;
    margin-right: rpx(6);
    width: rpx(6);
    height: rpx(20);
    opacity: 1;
    border-radius: rpx(3);
    background: #ff5959;
  }

  .coupon-item {
    margin-top: rpx(18);
    display: flex;
    width: rpx(295);
    height: rpx(80);
    border-radius: rpx(10);
    background: linear-gradient(
      163.98deg,
      rgba(252, 163, 151, 1) 0%,
      rgba(254, 74, 79, 1) 41.31%,
      rgba(253, 67, 75, 1) 76.54%,
      rgba(255, 140, 125, 1) 100%
    );
    .item-left {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      background: linear-gradient(270deg, rgba(255, 237, 232, 1) 0%, rgba(252, 248, 245, 1) 99.96%);
      border: rpx(3) solid rgba(254, 230, 232, 1);
      border-radius: rpx(10) 0 rpx(60) rpx(10);
      .item-top-num {
        position: absolute;
        top: rpx(-3);
        left: rpx(-2);
        padding: rpx(4) rpx(10);
        font-size: rpx(12);
        color: #fff;
        border-radius: rpx(10) 0 rpx(10) 0;
        background: rgba(255, 89, 89, 1);
      }
      .item-price {
        margin: rpx(4) 0 0 rpx(20);
        display: flex;
        align-items: baseline;
        font-size: rpx(14);
        color: rgba(140, 35, 6, 1);
        .item-price-num {
          margin: 0 rpx(5);
          color: #ff5959;
          font-size: rpx(20);
          font-weight: bold;
        }
        .font-size {
          font-size: rpx(28);
        }
      }
    }
    .item-right {
      padding: rpx(4) rpx(7);
      margin: rpx(27) rpx(8) rpx(27) 0;
      width: rpx(72);
      height: rpx(18);
      border-radius: rpx(13);
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 193, 128, 1) 100%);
      box-shadow: 0px rpx(4) rpx(4) rgba(112, 22, 3, 0.25);
      .item-right-btn {
        margin: 0;
        height: rpx(18);
        font-size: rpx(10);
        font-weight: 500;
        line-height: rpx(18);
        color: rgba(255, 89, 89, 1);
        text-align: center;
      }
    }
  }
  .active-desc {
    margin: rpx(10) rpx(20);
    font-size: rpx(12);
  }
}
