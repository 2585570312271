.m-checkbox {
  $m-width: rpx(18);
  display: inline-block;
  width: $m-width;
  height: $m-width;
  .m-size {
    width: $m-width;
    height: $m-width;
  }
}
