.tab-bar-block {
  height: rpx(48);
  background-color: white;
  display: flex;
  &.origin-red {
    .tab-bar-item {
      &.active {
        .label {
          color: $text-color-3 !important;
        }
        .active-line {
          background-color: $text-color-origin-red !important;
        }
      }
    }
  }
  .tab-bar-item {
    flex: 1;
    text-align: center;
    &.active {
      .label {
        color: $primary-color;
      }
      .active-line {
        background-color: $primary-color;
      }
    }
    .label {
      height: rpx(45);
      line-height: rpx(45);
      color: $text-color-3;
    }
    .active-line {
      width: 30%;
      height: rpx(3);
      border-radius: rpx(3);
      margin-left: 50%;
      transform: translateX(-50%);
      color: white;
    }
  }
}
