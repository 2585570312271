.login-page {
  min-height: 100vh;
  .input-block {
    .label {
      width: rpx(80);
    }
    .captcha-btn {
      min-width: rpx(80);
    }
  }
}
